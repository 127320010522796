import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useThemeSwitcher } from "react-css-theme-switcher";

import { onNavStyleChange, toggleCollapsedSideNav } from "../../appRedux/slices/Settings";
import Logo from "../../assets/logo/logo.svg";
import "./index.less";

import {
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    TAB_SIZE,
    THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import MainLogo from "../../components/Logo/MainLogo";

const SidebarLogo = () => {
    const { currentTheme } = useThemeSwitcher();
    const dispatch = useDispatch();
    const { width, themeType, navCollapsed } = useSelector(({ settings }) => settings);
    let navStyle = useSelector(({ settings }) => settings.navStyle);
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
        navStyle = NAV_STYLE_DRAWER;
    }
    return (
        <div className="gx-layout-sider-header gx-d-flex gx-align-items-center gx-justify-content-between">
            {width < TAB_SIZE ? (
                <Link to="/" className="gx-site-logo side-logo-container ">
                    <img src={Logo} width={40} height={40} alt="app-logo" />
                </Link>
            ) : null}
            {navStyle === NAV_STYLE_FIXED && (
                <Link to="/" className="gx-site-logo side-logo-container ">
                    <img src={Logo} width={40} height={40} alt="app-logo" />
                </Link>
            )}
            {navStyle === NAV_STYLE_MINI_SIDEBAR && width > TAB_SIZE ? (
                <div className="gx-fs-xxl">
                    <i
                        className={`gx-icon-btn icon icon-${"menu text-color-white"} ${
                            themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""
                        }`}
                        onClick={() => {
                            if (navStyle === NAV_STYLE_DRAWER) {
                                dispatch(toggleCollapsedSideNav(!navCollapsed));
                            } else if (navStyle === NAV_STYLE_FIXED) {
                                dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
                            } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                                dispatch(toggleCollapsedSideNav(!navCollapsed));
                            } else {
                                dispatch(onNavStyleChange(NAV_STYLE_FIXED));
                            }
                        }}
                    />
                </div>
            ) : null}

            {navStyle === NAV_STYLE_FIXED ? (
                <div className="gx-fs-xxl">
                    <i
                        className={`gx-icon-btn icon icon-${
                            navStyle === NAV_STYLE_MINI_SIDEBAR
                                ? "menu text-color-white"
                                : "close-circle text-color-white"
                        } ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
                        onClick={() => {
                            if (navStyle === NAV_STYLE_DRAWER) {
                                dispatch(toggleCollapsedSideNav(!navCollapsed));
                            } else if (navStyle === NAV_STYLE_FIXED) {
                                dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
                            } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                                dispatch(toggleCollapsedSideNav(!navCollapsed));
                            } else {
                                dispatch(onNavStyleChange(NAV_STYLE_FIXED));
                            }
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default SidebarLogo;
