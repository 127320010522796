import { Layout } from "antd";
import React from "react";

import Routes from "../../routes";
import Sidebar from "../Sidebar/index";

const { Content } = Layout;

const MainApp = () => {
    return (
        <Layout className="gx-app-layout gx-pb-0">
            <Sidebar />
            <Layout className="gx-pb-0">
                <Content className={`gx-layout-content justify-between`} id="layout">
                    <Routes />
                </Content>
            </Layout>
        </Layout>
    );
};
export default MainApp;

