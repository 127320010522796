import { createSlice } from "@reduxjs/toolkit";

const reportDrawerSlice = createSlice({
    name: "reportDrawer",
    initialState: {
        visible: false,
        obit: {}
    },
    reducers: {
        openDrawer: (state, action) => ({
            ...state,
            ...action?.payload
        })
    }
});
export const { openDrawer } = reportDrawerSlice.actions;
export default reportDrawerSlice.reducer;
