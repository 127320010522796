import { create } from "apisauce";
import { queryClient } from "../NextApp";
import { API_CONFIG, CONTENT_TYPE, HTTP_STATUS } from "../constants/APIs/index";
import { STORAGE_KEY } from "../constants/Storage";
import { getValue, isEmpty, redirectToLogin } from "../util/utils.service";
import LocalStorageService from "./local-storage.service";
import notificationService from "./notification.service";

export const BASE_URL = API_CONFIG.BASE_URL_PROD;

const apiSauceInstance = create({
    baseURL: BASE_URL
});

// export const setBaseRoute = (newBase) => {
//   apiSauceInstance.setBaseURL(
//     `${newBase}${API_SERVICE.CULTURE}${API_SERVICE.VERSION}`,
//   );
// };

async function get(url, queryParams, config) {
    const response = await apiSauceInstance.get(url, queryParams, config);
    return handleResponse(response);
}

async function post(url, data, config) {
    const response = await apiSauceInstance.post(url, data, config);
    return handleResponse(response);
}

async function put(url, data, config) {
    const response = await apiSauceInstance.put(url, data, config);
    return handleResponse(response);
}

async function patch(url, data, config) {
    const response = await apiSauceInstance.patch(url, data, config);
    return handleResponse(response);
}

async function deleteReq(url, queryParams, config) {
    const response = await apiSauceInstance.delete(url, queryParams, config);
    return handleResponse(response);
}

function handleResponse(response) {
    // console.log("API RESPONSE:", response);
    const mutatedResponse = {
        ok: response.ok,
        status: response.status,
        response: {
            code: getValue(response.data, "Code", response.status),
            message: getValue(response.data, "Message", response?.problem)
            //   errorCode: getValue(response.data, "response.errorCode", 400),
        }
    };
    switch (response?.problem) {
        // case "CANCEL_ERROR":
        //   mutatedResponse["response"]["message"] = "Request has been cancelled.";
        //   break;
        case "NETWORK_ERROR":
            mutatedResponse["response"]["message"] = "Network not available";
            break;
        case "SERVER_ERROR":
            mutatedResponse["response"]["message"] = "Something went wrong";
            break;
        case "TIMEOUT_ERROR":
            mutatedResponse["response"]["message"] = "Server didn't respond in time";
            break;
        default:
            break;
    }

    const data = getValue(response.data, "Data", response.data);
    if (response.status === HTTP_STATUS.UNAUTHORIZED) {
        let msg = mutatedResponse["response"]["message"] ?? "You are not authorized to perform this action";
        mutatedResponse["response"]["message"] = msg;
        // logoutUser();
        notificationService.error(msg);
        // LocalStorageService.remove(STORAGE_KEY.USER_INFO);
        // redirectToLogin();
    }
    if (response.status === HTTP_STATUS.SERVER_ERROR) {
        //Error message
        // toastService.fail(mutatedResponse["response"]["message"]);
    }

    if (response.ok) {
        return { ...mutatedResponse, data };
    }

    return { ...mutatedResponse, data: !isEmpty(data) ? data : null };
}

apiSauceInstance.addAsyncRequestTransform(async (request) => {
    const token = await LocalStorageService.get(STORAGE_KEY.TOKEN);
    request.headers["AUTHORIZATION"] = `${token}`;
    request.headers["Content-Type"] = CONTENT_TYPE.JSON;
    // request.headers["Access-Control-Allow-Origin"] = "*";
});

apiSauceInstance.addResponseTransform((response) => {
    if (response.status === HTTP_STATUS.UNAUTHORIZED) {
        localStorage.clear();
        queryClient.clear();
        redirectToLogin();
    }
});

export default {
    get,
    post,
    patch,
    put,
    delete: deleteReq
};
