import React from "react";

import SuccessResult from "../containers/Result";

// Public Imports
const ForgetPassword = React.lazy(() => import("../containers/AuthPages/ForgetPassword"));
const ResetPassword = React.lazy(() => import("../containers/AuthPages/ResetPassword"));
const SetPassword = React.lazy(() => import("../containers/AuthPages/SetPassword/SetPassword"));
const Login = React.lazy(() => import("../containers/AuthPages/Login"));

// Protected Imports
const Dashboard = React.lazy(() => import("../containers/DashboardPages/Dashboard/Dashboard.js"));
const Ads = React.lazy(() => import("../containers/DashboardPages/AdvertiserListing/AdvertiserListing.js"));
const AdsListing = React.lazy(() => import("../containers/DashboardPages/AdsListing/AdsListing.js"));
const Obituaries = React.lazy(() => import("../containers/DashboardPages/Obituaries/Obituaries.js"));
const RequestedObituaries = React.lazy(() =>
    import("../containers/DashboardPages/RequestedObituaries/RequestedObituaries")
);
const PremiumObituaries = React.lazy(() => import("../containers/DashboardPages/PremiumObituaries"));
const ClaimedObituaries = React.lazy(() => import("../containers/DashboardPages/ClaimedObituaries"));
const EditRequestedObituary = React.lazy(() =>
    import("../containers/DashboardPages/EditRequestedObituary/EditRequestedObituary")
);
const Reports = React.lazy(() => import("../containers/DashboardPages/Reports/Reports.js"));
const RemovalRequests = React.lazy(() => import("../containers/DashboardPages/RemovalRequests"));
const Users = React.lazy(() => import("../containers/DashboardPages/Users/Users.js"));
const ViewUser = React.lazy(() => import("../containers/DashboardPages/Users/ViewUser/ViewUser"));
const Subscription = React.lazy(() => import("../containers/DashboardPages/Subscription/Subscription.js"));
const SimilarObituaries = React.lazy(() => import("../containers/DashboardPages/SimilarObituaries/index.js"));
const CityDetails = React.lazy(() => import("../containers/DashboardPages/CityDetails/CityDetails"));
const ObituaryLinks = React.lazy(() => import("../containers/DashboardPages/ObituaryLinks"));

export const UNAUTHENTICATED_ROUTES = {
    LOGIN: "/login",
    RESET_PASSWORD: "/reset-password/:resetToken",
    FORGET_PASSWORD: "/forget-password",
    SET_PASSWORD: "/set-password/:userId",
    EVENT_SUCCESS: "/event-submitted"
};

export const AUTHENTICATED_ROUTES = {
    DASHBOARD: "/",
    ADVERTISERS: "/advertisers",
    VIEW_ADVERTISER: "/advertiser/:advertiserId",
    OBITUARIES: "/obituaries",
    VIEW_OBITUARY: "/obituary/:obituaryId",
    REQUESTED_OBITUARIES: "/requested-obituaries",
    VIEW_REQUESTED_OBITUARY: "/obituary/:obituaryId",
    ADD_NEW_OBITUARY: "/add-new-obituary",
    REPORTS: "/reports",
    USERS: "/users",
    VIEW_USER: "/users/:id",
    SUBSCRIPTIONS: "/subscriptions",
    NOTIFICATIONS: "/notifications",
    PROFILE: "/profile",
    SIMILAR_OBITUARIES: "/similar-obituaries",
    GROUP_COUNT: "/group-count",
    PREMIUM_OBITUARIES: "/premium-obituaries",
    CLAIMED_OBITUARIES: "/claimed-obituaries",
    OBITUARY_LINKS: "/obituary-links",
    REMOVAL_REQUESTS: "/removal-requests"
};

export const PUBLIC_ROUTES = [
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.LOGIN,
        component: Login,
        title: "Login"
    },
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.FORGET_PASSWORD,
        component: ForgetPassword,
        title: "Forget Password"
    },
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.RESET_PASSWORD,
        component: ResetPassword,
        title: "Reset Password"
    },
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.SET_PASSWORD,
        component: SetPassword,
        title: "Set Password"
    },
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.EVENT_SUCCESS,
        component: SuccessResult,
        title: "Success Result"
    }
];

export const ROLES = {
    SUPER_ADMIN: "SUPER ADMIN",
    VENDOR: "VENDOR"
};

export const ALL_APP_ROUTES = {
    [ROLES.SUPER_ADMIN]: [
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.DASHBOARD,
            component: Dashboard,
            title: "Dashboard"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.ADVERTISERS,
            component: Ads,
            title: "Ads"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.VIEW_ADVERTISER,
            component: AdsListing,
            title: "Ads Listing"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.OBITUARIES,
            component: Obituaries,
            title: "Obituaries"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.VIEW_OBITUARY,
            component: EditRequestedObituary,
            title: "View Obituary"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.REQUESTED_OBITUARIES,
            component: RequestedObituaries,
            title: "Requested Obituaries"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.VIEW_REQUESTED_OBITUARY,
            component: EditRequestedObituary,
            title: "View Requested Obituaries"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.ADD_NEW_OBITUARY,
            component: EditRequestedObituary,
            title: "Add New Obituary"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.REPORTS,
            component: Reports,
            title: "Reports"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.REMOVAL_REQUESTS,
            component: RemovalRequests,
            title: "Removal Requests"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.USERS,
            component: Users,
            title: "Users"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.VIEW_USER,
            component: ViewUser,
            title: "View User"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.SUBSCRIPTIONS,
            component: Subscription,
            title: "Subscriptions"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.PREMIUM_OBITUARIES,
            component: PremiumObituaries,
            title: "Premium Obituaries"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.CLAIMED_OBITUARIES,
            component: ClaimedObituaries,
            title: "Claimed Obituaries"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.SIMILAR_OBITUARIES,
            component: SimilarObituaries,
            title: "Similar Obituaries"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.GROUP_COUNT,
            component: CityDetails,
            title: "City Details"
        },
        {
            exact: true,
            path: AUTHENTICATED_ROUTES.OBITUARY_LINKS,
            component: ObituaryLinks,
            title: "Obituary Links"
        }
    ]
};
