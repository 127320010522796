import { createAction, createSlice } from "@reduxjs/toolkit";
import { NAV_STYLE_FIXED, THEME_COLOR_SELECTION_PRESET, THEME_TYPE_LITE } from "../../constants/ThemeSetting";
const locationChange = createAction("@@router/LOCATION_CHANGE");

const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        navStyle: NAV_STYLE_FIXED,
        themeType: THEME_TYPE_LITE,
        colorSelection: THEME_COLOR_SELECTION_PRESET,
        navCollapsed: true,
        pathname: "",
        width: window.innerWidth,
        height: window.innerHeight,
        isDirectionRTL: false
    },
    reducers: {
        toggleCollapsedSideNav: (state, action) => ({
            ...state,
            navCollapsed: action.payload
        }),
        updateWindowWidth: (state, action) => ({
            ...state,
            width: action.payload
        }),
        updateWindowHeight: (state, action) => ({
            ...state,
            height: action.payload
        }),
        setThemeType: (state, action) => ({
            ...state,
            themeType: action.payload
        }),
        setThemeColor: (state, action) => ({
            ...state,
            colorSelection: action.colorSelection
        }),
        onNavStyleChange: (state, action) => ({
            ...state,
            navStyle: action.payload
        }),
        onLayoutTypeChange: (state, action) => ({
            ...state,
            layoutType: action.payload
        })
    },
    extraReducers: (builder) => {
        builder
            .addCase(locationChange, (state, action) => ({
                ...state,
                pathname: action.payload.location.pathname,
                navCollapsed: false
            }))
            .addDefaultCase(() => {});
    }
});

export const {
    toggleCollapsedSideNav,
    updateWindowWidth,
    updateWindowHeight,
    setThemeType,
    setThemeColor,
    onNavStyleChange,
    onLayoutTypeChange
} = settingsSlice.actions;
export default settingsSlice.reducer;
