import { AUTHENTICATED_ROUTES } from "../routes/constant";

export const ROLE_DEFAULT_ROUTE = {
    Admin: AUTHENTICATED_ROUTES.DASHBOARD,
    User: AUTHENTICATED_ROUTES.DASHBOARD
};

export const ROLES = {
    GlobalAdmin: "GlobalAdmin",
    User: "User",
    RegionalAdmin: "RegionalAdmin"
};

export const MAIN_SIDE_BAR_OPTIONS = {
    Admin: {
        upperMenu: [
            {
                text: "DASHBOARD",
                linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
                selectedOptionKey: "dashboard",
                icon: "usd-dashboard"
            },
            {
                text: "AUDIENCE STATISTICS",
                linkTo: AUTHENTICATED_ROUTES.GROUP_COUNT,
                selectedOptionKey: "group-count",
                icon: "usd-resources"
            },
            {
                text: "ADS",
                linkTo: AUTHENTICATED_ROUTES.ADVERTISERS,
                selectedOptionKey: "ads",
                icon: "usd-upload"
            },
            {
                text: "OBITUARIES",
                linkTo: AUTHENTICATED_ROUTES.OBITUARIES,
                selectedOptionKey: "obituaries",
                icon: "usd-administrators"
            },
            {
                text: "REQUESTED OBITUARIES",
                linkTo: AUTHENTICATED_ROUTES.REQUESTED_OBITUARIES,
                selectedOptionKey: "requested-obituaries",
                icon: "usd-activities"
            },
            {
                text: "PREMIUM OBITUARIES",
                linkTo: AUTHENTICATED_ROUTES.PREMIUM_OBITUARIES,
                selectedOptionKey: "premium-obituaries",
                icon: "usd-cost"
            },
            {
                text: "CLAIMED OBITUARIES",
                linkTo: AUTHENTICATED_ROUTES.CLAIMED_OBITUARIES,
                selectedOptionKey: "claimed-obituaries",
                icon: "usd-administrators"
            },
            {
                text: "REPORTS",
                linkTo: AUTHENTICATED_ROUTES.REPORTS,
                selectedOptionKey: "reports",
                icon: "usd-comments"
            },
            {
                text: "REMOVAL REQUESTS",
                linkTo: AUTHENTICATED_ROUTES.REMOVAL_REQUESTS,
                selectedOptionKey: "removal-requests",
                icon: "usd-comments"
            },
            {
                text: "USERS",
                linkTo: AUTHENTICATED_ROUTES.USERS,
                selectedOptionKey: "users",
                icon: "usd-users"
            },
            {
                text: "OBITUARY LINKS",
                linkTo: AUTHENTICATED_ROUTES.OBITUARY_LINKS,
                selectedOptionKey: "users",
                icon: "usd-administrators"
            }

            // {
            //     text: "SIMILAR OBITUARIES",
            //     linkTo: AUTHENTICATED_ROUTES.SIMILAR_OBITUARIES,
            //     selectedOptionKey: "similar-obituaries",
            //     icon: "usd-users"
            // }
            // {
            //     text: "SUBSCRIPTIONS",
            //     linkTo: AUTHENTICATED_ROUTES.SUBSCRIPTIONS,
            //     selectedOptionKey: "subscriptions",
            //     icon: "usd-cost"
            // }
        ],
        lowerMenu: []
    }
};
