import { CloseCircleOutlined } from "@ant-design/icons";
import { Select, Tag } from "antd";
import React from "react";
import "./CustomSelect.less";

function tagRender(props) {
    const { label, closable, onClose } = props;
    return (
        <Tag className="customTag" closable={closable} onClose={onClose}>
            {label}
        </Tag>
    );
}

const CustomSelect = (props) => {
    const { className, children, handleClearCityLookup, onDelete } = props;

    return (
        <Select
            value={props.defaultValue}
            className={`custom-select-optimize ${className}`}
            tagRender={tagRender}
            clearIcon={<CloseCircleOutlined />}
            filterOption={true}
            onChange={(val) => handleClearCityLookup(val)}
            optionFilterProp="label"
            onDeselect={onDelete}
            {...props}
        >
            {children}
        </Select>
    );
};

export default CustomSelect;
