import { Button, Modal } from "antd";
import React from "react";
import { useHistory } from "react-router";

import { useLogout } from "../../apiService/adminQueries";

import "./index.less";

const ConfirmationModal = ({
    visible = false,
    onCancel,
    loading,
    onSuccess,
    isConfirmation,
    onPress,
    confirmationText
}) => {
    const { mutateAsync: logout, isLoading } = useLogout({ onSuccess });

    return (
        <>
            <Modal
                className="logout-modal"
                centered
                title="Confirmation"
                visible={visible}
                onOk={onSuccess}
                onCancel={onCancel}
                footer={[
                    <Button key="back" onClick={onCancel} className="montserrat-medium">
                        Cancel
                    </Button>,
                    <Button
                        loading={isConfirmation ? loading : isLoading}
                        disabled={isConfirmation ? loading : isLoading}
                        onClick={isConfirmation ? onPress : logout}
                        className="confirm-button montserrat-medium"
                    >
                        {isConfirmation ? "Yes" : "Logout"}
                    </Button>
                ]}
            >
                <div className="modal-content">
                    <i className="icon usd-warning pending-color gx-mb-3 " style={{ fontSize: "60px" }} />
                    <h3 className="montserrat-medium gx-fs-xl">Are you sure?</h3>
                    <p className="montserrat-regular gx-fs-md text-color-secondary">
                        {isConfirmation ? confirmationText : "Are you sure you want to logout the application?"}
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default ConfirmationModal;

