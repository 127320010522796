import { useMutation, useQuery } from "react-query";
import { request } from "../actions/ServiceAction";
import { API_CONFIG, API_ROUTES } from "../constants/APIs/index";
import { STORAGE_KEY } from "../constants/Storage";
import { createDynamicUrl } from "../util/utils.service";

export function useLogin(options) {
    return useMutation(async (payload) => {
        const { data } = await request({
            url: API_ROUTES.login,
            method: API_CONFIG.POST,
            params: payload
        });

        return data;
    }, options);
}

export function useLogout(options) {
    return useMutation(
        async () =>
            request({
                url: API_ROUTES.logout,
                method: API_CONFIG.GET
            }),
        options
    );
}

export function useGetUserListing(params) {
    return useQuery(
        [STORAGE_KEY.USERS, params],
        async () => {
            const { data } = await request({
                url: API_ROUTES.userListing,
                method: API_CONFIG.GET,
                params
            });
            return data;
        },
        { keepPreviousData: true, staleTime: Infinity }
    );
}

export function useGetDetails() {
    return useQuery([STORAGE_KEY.USERS], async () => {
        const { data } = await request({
            url: API_ROUTES.details,
            method: API_CONFIG.GET
        });
        return data;
    });
}

export function useGetUserDetails(params) {
    return useQuery([STORAGE_KEY.USER_INFO, params.id, "details"], async () => {
        const { data } = await request({
            url: createDynamicUrl(API_ROUTES.userDetails, params),
            method: API_CONFIG.GET
        });
        return data.User;
    });
}

export function useGetUserObituaries(id, payload) {
    return useQuery([STORAGE_KEY.USER_INFO, id, `obits ${payload.Claimed}`], async () => {
        const { data } = await request({
            url: createDynamicUrl(API_ROUTES.userObituaries, { userId: id }),
            method: API_CONFIG.POST,
            params: payload
        });
        return data.Obituarys;
    });
}

export function useGetUserCities(params) {
    return useQuery([STORAGE_KEY.USER_INFO, params.id, "cities"], async () => {
        const { data } = await request({
            url: createDynamicUrl(API_ROUTES.userCities, params),
            method: API_CONFIG.GET
        });
        return data.UserFollowedCities;
    });
}

export function useDeactivateUser(options) {
    return useMutation(async (params) => {
        const { data } = await request({
            url: createDynamicUrl(API_ROUTES.deactivateUser, params),
            method: API_CONFIG.PUT,
            params: {}
        });
        return data;
    }, options);
}

export function useSetUserPassword(options) {
    return useMutation(async (params) => {
        const { data } = await request({
            url: createDynamicUrl(API_ROUTES.setUserPassword, { id: params.id }),
            method: API_CONFIG.PUT,
            params: { Password: params.Password }
        });
        return data;
    }, options);
}

