import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CustomRoute from "./CustomRoute";

import { PUBLIC_ROUTES, UNAUTHENTICATED_ROUTES } from "./constant";

const Login = React.lazy(() => import("../containers/AuthPages/Login"));
// const ForgetPassword = React.lazy(() => import("../containers/AuthPages/ForgetPassword"));
// const ResetPassword = React.lazy(() => import("../containers/AuthPages/ResetPassword"));
// const SetPassword = React.lazy(() => import("../containers/AuthPages/SetPassword/SetPassword"));

export default function UnAuthenticatedApp() {
    const routes = PUBLIC_ROUTES;
    return (
        <Switch>
            {routes.map((item) => (
                <CustomRoute exact path={item.path} component={item.component} title={item.title} />
            ))}
            <Route exact path="*" component={Login}>
                <Redirect to={UNAUTHENTICATED_ROUTES.LOGIN} />
            </Route>
        </Switch>
    );
}
